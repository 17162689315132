<content-loading-animation *ngIf="loading"></content-loading-animation>
<app-form-wrapper [formGroup]="form" *ngIf="!loading" [titleMsg]="'TAG' | translate" [isEdit]="isEdit" [isClone]="false">
    <!-- Name -->
    <app-input-generic-field
        [formControl]="form.controls.name"
        [textFieldName]="'NAME' | translate"
        [isParentFormSubmitted]="submitted"
        [checkDuplicateNames]="tagNames$ | async"
        [patternErrorMessage]="patternErrorMessage"
        type="text"
    ></app-input-generic-field>
    <app-checkbox-field [label]="'PRIORITY' | translate" [formControl]="form.controls.vip" id="vip"></app-checkbox-field>

    <app-zx-form-bottom-buttons
        (submit)="onSubmit()"
        [isFormValid]="form.valid"
        [(submitted)]="submitted"
        [isSaving]="saving"
        [isEdit]="isEdit"
        [cancelUrlNavigate]="constants.urls.configuration.tags"
    >
    </app-zx-form-bottom-buttons>
</app-form-wrapper>
