import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { map } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { TagsService } from "../tags.service";
import { Tag } from "../../../../models/shared";
import { TitleService } from "../../../../services/title.service";
import { FormBuilder, Validators } from "@angular/forms";
import _ from "lodash";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-tag-form",
    templateUrl: "./tag-form.component.html"
})
export class TagFormComponent implements OnInit {
    tag: Tag;
    tagId: number;
    action: string;

    submitted = false;
    isEdit = false;

    loading = true;
    saving = false;
    constants = Constants;

    private route = inject(ActivatedRoute);
    private translate = inject(TranslateService);
    private router = inject(Router);
    private ts = inject(TagsService);
    private titleService = inject(TitleService);
    private formBuilder = inject(FormBuilder);

    patternErrorMessage = this.translate.instant("CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:") + " \" ' `";
    tagNames$ = this.ts.tags.pipe(
        map(tags => {
            if (this.tag) tags = tags.filter(tag => tag.id !== this.tag.id);
            return tags.map(tags => tags.name);
        })
    );
    form = this.formBuilder.group({
        name: [
            "",
            [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(100),
                Validators.pattern(Constants.validators.name)
            ]
        ],
        vip: [false]
    });
    private prepForm() {
        if (this.isEdit)
            this.form.setValue({
                name: this.tag.name,
                vip: this.tag.vip
            });

        // Set Title
        this.titleService.setTitle("TAG", this.action, this.tag);
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.tagId = parseInt(params.id, 10);
        this.action = params.action;
        if (this.action === "edit") this.isEdit = true;
        this.ts.refreshTags();
        if (this.tagId)
            this.tag = await firstValueFrom(
                this.ts.tags.pipe(map(tags => _.cloneDeep(tags.find(t => t.id === this.tagId))))
            );

        this.loading = false;

        this.prepForm();
    }

    async onSubmit() {
        this.saving = true;
        const model = this.form.getRawValue();

        const result = this.isEdit ? await this.ts.updateTag(this.tag, model) : await this.ts.addTag(model);

        if (result) this.router.navigate([Constants.urls.configuration.tags]);

        this.saving = false;
    }
}
