import { Component, OnInit, OnDestroy, ComponentRef } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UsersService } from "../../../account-management/users/users.service";
import { KeyMap, Tag } from "../../../../models/shared";

import { TagsService } from "../tags.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";

import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";

@Component({
    selector: "app-tag-list",
    templateUrl: "./tag-list.component.html"
})
export class TagListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    tags: Tag[];
    selectedRows: Array<Tag> = [];

    isAdmin: boolean;
    urls = Constants.urls;

    private tagsSubscription: Subscription;
    private tagsBS$ = new BehaviorSubject<Tag[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Tag>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: Tag): string => row?.name,
            textValue: (row: Tag): string => row?.name
        },
        {
            header: this.translate.instant("PRIORITY"),
            columnDef: "priority",
            width: 70,
            visible: true,
            component: IconColumnComponent,
            assignComponentsInputs: (componentRef: ComponentRef<IconColumnComponent>, row: KeyMap<Tag>) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = row.vip;
            },
            sortBy: (row: Tag) => (row.vip ? 1 : 0),
            textValue: (row: Tag) => (row.vip ? 1 : 0)
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 60,
            visible: true,
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<Tag, Promise<void>>({
                canEditCallBack: () => true,
                canDeleteCallBack: () => true,
                editRef: row => this.edit(row),
                deleteRef: row => this.delete(row)
            })
        }
    ];

    private tourSteps = TourSteps.tagList;

    constructor(
        private router: Router,
        private ts: TagsService,
        private modalService: ModalService,
        private userService: UsersService,
        public mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService,
        public tourService: TourService
    ) {
        // Set Title
        this.titleService.setTitle("TAGS", "");
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.tagsSubscription = this.ts.tags.subscribe(tags => {
            this.tags = tags;
            if (this.tags) {
                this.prepTableData();
                this.loading = false;
            }
        });

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy() {
        this.tagsSubscription.unsubscribe();
    }

    async refresh() {
        this.refreshing = true;

        const tags = this.ts.refreshTags().toPromise();
        await Promise.all([tags]);

        this.refreshing = false;
    }

    async multiAction(action: string, func: (tag: Tag, force_delete: boolean) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "TAG", this.selectedRows, func, {
            checkbox: this.translate.instant("FORCE_DELETE")
        });

        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple access tags");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (tag: Tag, force_delete: boolean) => this.ts.deleteTag(tag, force_delete));
    }

    edit(tag: Tag): void {
        this.router.navigate([Constants.urls.configuration.tags, tag.id, "edit"]);
    }

    async delete(tag: Tag) {
        const result = await this.modalService.confirm(
            "DELETE",
            "TAG",
            async (force_delete: boolean) => {
                const id = tag.id;
                const result = await this.ts.deleteTag(tag, force_delete);
                if (result) {
                    this.mixpanelService.sendEvent("delete access tag", { id });
                } else return false;
            },
            tag.name,
            {
                checkbox: this.translate.instant("FORCE_DELETE")
            }
        );
        if (!result) {
            this.selectedRows = [];
        }
    }

    get tags$() {
        return this.tagsBS$.asObservable();
    }

    private prepTableData() {
        if (this.tags) {
            this.tagsBS$.next([...this.tags]);
        }
    }
}
